import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IRacunDto, TipRacuna } from '@kodit/core/data-api';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-osnovne-informacije-racuna',
  templateUrl: './osnovne-informacije-racuna.component.html',
  styleUrls: ['./osnovne-informacije-racuna.component.scss'],
})
export class OsnovneInformacijeRacunaComponent implements OnInit, OnDestroy {
  /** Subs */
  private _datumPrometaChangeSub: Subscription = new Subscription();
  private _rokPlacanjaChangeSub: Subscription = new Subscription();
  private _rokVazenjaChangeSub: Subscription = new Subscription();

  /** Props */

  /** Flags */
  displayDatumObracunaPdv: boolean;
  displayDatumIzdavanja: boolean;
  displayPromet: boolean;
  displayMestoIzdavanja: boolean;
  displayDatumValute: boolean;
  displayRokPlacanja: boolean;
  displayRokVazenja: boolean;
  displayDatumUplate: boolean;
  displayDatumDospeca: boolean;
  displayDatumRefDokumenta: boolean;

  /** I/O */
  @Input() racunFormGroup: FormGroupTypeSafe<IRacunDto>;
  @Input() rokVazenjaCtrl: AbstractControl;

  constructor(private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this._setFlags();
    this._setSubs();
    if(this.racunFormGroup.value.tip == TipRacuna.PREDRACUN
      || this.racunFormGroup.value.tip == TipRacuna.PONUDA){
      this.rokPlacanjaChanged(this.rokVazenjaCtrl.value);
    }
  }

  rokPlacanjaChanged(rokPlacanja: number) {
    if (!this.racunFormGroup.value.datumPrometa) {
      return;
    }
    this._calculateDatumValute(
      this.racunFormGroup.value.datumPrometa,
      rokPlacanja
    );
  }

  datumPrometaChanged(datumIzdavanja: Date) {
    if (!datumIzdavanja) {
      return;
    }
    this._calculateDatumValute(
      datumIzdavanja,
      this.racunFormGroup.value.rokPlacanja
    );
  }

  private _calculateDatumValute(date: Date, days: number) {
    const newValue = new Date(date);
    newValue.setDate(date.getDate() + days);
    this.racunFormGroup.controls.datumValute.patchValue(newValue);
    this.racunFormGroup.controls.datumValute.updateValueAndValidity();
  }

  get jeEditMode(): boolean {
    return this._route.snapshot.data.racun?.racunDto?.id;
  }

  private _setFlags() {
    const jePredracun = this.racunFormGroup.value.tip === TipRacuna.PREDRACUN;
    const jeOtpremnica = this.racunFormGroup.value.tip === TipRacuna.OTPREMNICA;
    const jeAvansniRacun =
      this.racunFormGroup.value.tip === TipRacuna.AVANSNI_RACUN;
    const jeRadniNalog =
      this.racunFormGroup.value.tip === TipRacuna.RADNI_NALOG;
    const jeIzmenaOsnovice =
      this.racunFormGroup.value.tip === TipRacuna.DOKUMENT_O_SMANJENJU ||
      this.racunFormGroup.value.tip === TipRacuna.DOKUMENT_O_POVECANJU;
    const jeIzlaznaFaktura =
      this.racunFormGroup.value.tip === TipRacuna.IZLAZNA_FAKTURA;
    const jeDokumentOPovecanju =
      this.racunFormGroup.value.tip === TipRacuna.DOKUMENT_O_POVECANJU;
    const jePonuda = this.racunFormGroup.value.tip === TipRacuna.PONUDA;
    const jeUlaznaFaktura =
      this.racunFormGroup.value.tip === TipRacuna.ULAZNA_FAKTURA;

    this.displayDatumObracunaPdv =
      jeIzlaznaFaktura || jeAvansniRacun || jeDokumentOPovecanju;

    this.displayDatumIzdavanja = jeOtpremnica || jeUlaznaFaktura;
    this.displayPromet = jeIzlaznaFaktura || jeOtpremnica || jeUlaznaFaktura;
    this.displayMestoIzdavanja = !jeRadniNalog;
    this.displayDatumValute = jeIzlaznaFaktura || jeDokumentOPovecanju;
    this.displayRokPlacanja =
      !jePredracun &&
      !jeAvansniRacun &&
      !jeIzmenaOsnovice &&
      !jeRadniNalog &&
      !jePonuda;
    this.displayRokVazenja = jePredracun || jePonuda;
    this.displayDatumUplate = jeAvansniRacun;
  }

  private _setSubs() {
    this._rokPlacanjaChangeSub = this.racunFormGroup.controls.rokPlacanja.valueChanges.subscribe(
      (rokPlacanja) => {
        if(this.racunFormGroup.value.tip !== TipRacuna.PONUDA && this.racunFormGroup.value.tip !== TipRacuna.PREDRACUN) {
          this.rokPlacanjaChanged(rokPlacanja);
        }
      }
    );

    this._rokVazenjaChangeSub = this.rokVazenjaCtrl?.valueChanges.subscribe(
      (rokVazenja) => {
        this.rokPlacanjaChanged(rokVazenja);
      }
    );

    this._datumPrometaChangeSub = this.racunFormGroup.controls.datumPrometa.valueChanges.subscribe(
      (datumIzdavanja) => {
        this.datumPrometaChanged(datumIzdavanja);
      }
    );
  }

  ngOnDestroy(): void {
    this._datumPrometaChangeSub.unsubscribe();
    this._rokPlacanjaChangeSub.unsubscribe();
    this._rokVazenjaChangeSub?.unsubscribe();
  }
}
