import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import {
  DatumObracunaPdv,
  IGetFormConfigurationResponse,
  IRacunDto,
  Paritet,
  PrikazRabata,
  StatusRacuna,
  StatusUplateRacuna,
  TipEntiteta,
  TipRacuna,
  VrstaRacuna
} from '@kodit/core/data-api';
import { Injectable } from '@angular/core';
import { StavkaFormService } from './stavka-racuna-form.service';
import { OznakaFormService } from '../common/oznaka-form.service';
import { PodaciUplateFormService } from './podaci-uplate-form.service';
import { StrankaRacunaFormService } from './stranka-racuna-form.service';
import { AvansnaUplataRacunaFormService } from './avansna-uplata-racuna-form.service';
import { EMPTY_GUID, SERBIA_CURRENCY_ALPHA_CHAR } from '@kodit/core/shared';
import { VezaniRacunFormService } from './vezani-racun-form.service';
import { PodaciUplateDevizniFormService } from './podaci-uplate-devizni-form.service';
import { RacunUkupanIznosFormService } from './racun-ukupan-iznos-form.service';
import { RelevantniDokumentListItemFormService } from './relevantni-dokument-form.service';

@Injectable({
  providedIn: 'root',
})
export class BaseRacunFormService {
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _stavkaFS: StavkaFormService,
    private _oznakaFS: OznakaFormService,
    private _podaciUplateFS: PodaciUplateFormService,
    private _strankaRacunaFS: StrankaRacunaFormService,
    private _avansniRacunFS: AvansnaUplataRacunaFormService,
    private _vezaniRacunFS: VezaniRacunFormService,
    private _podaciUplateDevizniFS: PodaciUplateDevizniFormService,
    private _ukupanIznosFS: RacunUkupanIznosFormService,
    private _relevantniDokumentFS: RelevantniDokumentListItemFormService
  ) {}

  /**
   * Konvertuje bazni model za dokument i od njega pravi reaktivnu formu (form grupu)
   *
   * Ukoliko se model ne prosledi (ili je null) kreira se forma sa default vrednostima
   *
   * @param model Bazni model dokumenta od koga zelimo da kreiramo formu
   * @returns Reaktivnu formu kao FormGroup
   */
  public GetBaseRacunFormGroup(
    tip: TipRacuna,
    vrsta: VrstaRacuna,
    tipEntiteta: TipEntiteta,
    formConfiguration: IGetFormConfigurationResponse,
    model?: IRacunDto,
    isJedinicaMereRequired: boolean = true
  ): FormGroupTypeSafe<IRacunDto> {
    return this._fb.group<IRacunDto>({
      id: new FormControl(model?.id),
      ulazniRacunIdSef: new FormControl(model?.ulazniRacunIdSef),
      izlazniRacunIdSef: new FormControl(model?.izlazniRacunIdSef),
      broj: new FormControl(model?.broj),
      originalanBroj: new FormControl(model?.originalanBroj, {
        validators: [Validators.maxLength(128)],
      }),
      praviloSerijskogBrojaId: new FormControl(model?.praviloSerijskogBrojaId, {
        validators: [!model ? Validators.required : Validators.nullValidator],
      }),
      datumObracunaPdv: new FormControl(
        model?.datumObracunaPdv ??
          (tip === TipRacuna.DOKUMENT_O_SMANJENJU
            ? DatumObracunaPdv.EMPTY
            : tip === TipRacuna.DOKUMENT_O_POVECANJU
            ? DatumObracunaPdv.NA_DAN_IZDAVANJA
            : DatumObracunaPdv.NA_DAN_PROMETA)
      ),
      datumIzdavanja: new FormControl(model?.datumIzdavanja ?? new Date(), {
        validators: [Validators.required],
      }),
      datumPrometa: new FormControl(
        // ako nije edit, ne datumPrometa ne uzimamo iz konfiguracije za ponude/predracune
        model?.datumPrometa ?? ((tip === TipRacuna.PREDRACUN || tip === TipRacuna.PONUDA) ? new Date() : formConfiguration.datumPrometa),
        {
          validators:
            tip !== TipRacuna.PREDRACUN
              ? [Validators.required]
              : [Validators.nullValidator],
        }
      ),
      datumValute: new FormControl(model?.datumValute ?? this._getTomorrow(), {
        validators:
          tip !== TipRacuna.PREDRACUN
            ? [Validators.required]
            : [Validators.nullValidator],
      }),
      mestoIzdavanja: new FormControl(
        model?.mestoIzdavanja ?? formConfiguration?.mestoIzdavanjaPrometa,
        {
          validators: [Validators.required, Validators.maxLength(128)],
        }
      ),
      nosiocJavneNabavke: new FormControl(model?.nosiocJavneNabavke),
      mozeNaSef: new FormControl(model?.mozeNaSef ?? true),
      mestoPrometa: new FormControl(
        model?.mestoPrometa ?? formConfiguration?.mestoIzdavanjaPrometa,
        {
          validators:
            tip !== TipRacuna.PREDRACUN
              ? [Validators.required, Validators.maxLength(128)]
              : [Validators.nullValidator],
        }
      ),
      napomena: new FormControl(model?.napomena, {
        validators: [Validators.maxLength(2048)],
      }),
      ukupanIznosDto: this._ukupanIznosFS.GetFormGroup(
        model?.ukupanIznosDto,
        tip
      ),
      ukupnoRabat: new FormControl(model?.ukupnoRabat ?? 0, {
        validators: [Validators.required],
      }),
      strankaRacunaDto: this._strankaRacunaFS.GetStrankaRacunaFormGroup(
        model?.strankaRacunaDto
      ),
      createdBy: new FormControl(model?.createdBy),
      createdOn: new FormControl(model?.createdOn ?? new Date()),
      lastModifiedBy: new FormControl(model?.lastModifiedBy),
      lastModifiedOn: new FormControl(model?.lastModifiedOn ?? new Date()),
      stavkeDto: this._stavkaFS.GetFormArray(
        model?.stavkeDto,
        isJedinicaMereRequired,
        formConfiguration?.prikaziPDV ?? true,
        vrsta === VrstaRacuna.ULAZNI
      ),
      vrsta: new FormControl(vrsta),
      tip: new FormControl(tip),
      oznakeDto: this._oznakaFS.GetOznakeFormArray(model?.oznakeDto),
      napomenaOPoreskomOslobadanju: new FormControl(
        model?.napomenaOPoreskomOslobadanju ?? 'Nema',
        {
          validators: [Validators.maxLength(256)],
        }
      ),
      tipEntiteta: new FormControl(tipEntiteta),
      tekuciRacunId: new FormControl(model?.tekuciRacunId ?? null, {
        validators:
          vrsta == VrstaRacuna.IZLAZNI &&
          tip !== TipRacuna.OTPREMNICA &&
          tip !== TipRacuna.RADNI_NALOG &&
          !model?.podaciUplateDevizniDto?.iban // Proveravamo da li je devizni
            ? [Validators.required, Validators.min(1)]
            : [],
      }),
      vezaniRacuniDto: this._vezaniRacunFS.getFormArray(model?.vezaniRacuniDto),
      podaciUplateDto: this._podaciUplateFS.GetPodaciUplateFormGroup(
        model?.podaciUplateDto
      ),
      podaciUplateDevizniDto: this._podaciUplateDevizniFS.GetPodaciUplateDevizniFormGroup(
        model?.podaciUplateDevizniDto
      ),
      status: new FormControl(model?.status ?? StatusRacuna.NACRT),
      valutaId: new FormControl(model?.valutaId ?? EMPTY_GUID),
      valutaText: new FormControl(
        model?.valutaText ?? SERBIA_CURRENCY_ALPHA_CHAR
      ),
      podtipDokumenta: new FormControl(model?.podtipDokumenta),
      // transakcijeIzvodaDto: this._transakcijeIzvodaFS.GetTransakcijeIzvodaFormArray(
      //   model?.transakcijeIzvodaDto
      // ),
      rokPlacanja: new FormControl(model?.rokPlacanja ?? 1, {
        validators: [Validators.min(0)],
      }),
      statusUplate: new FormControl(
        model?.statusUplate ??
          (tip === TipRacuna.AVANSNI_RACUN
            ? StatusUplateRacuna.NOV
            : StatusUplateRacuna.NEPLACEN)
      ),
      transakcijaZaRacunDto: model?.transakcijaZaRacunDto
        ? new FormGroup({
            transakcijaIzvodaId: new FormControl(
              model.transakcijaZaRacunDto.transakcijaIzvodaId
            ),
            uplaceniIznos: new FormControl(
              model.transakcijaZaRacunDto.uplaceniIznos
            ),
          })
        : null,
      uplaceno: new FormControl(model?.uplaceno ?? 0),
      avansneUplateRacunaTableDto: this._avansniRacunFS.GetAvansneUplateRacunaFormArray(
        model?.avansneUplateRacunaTableDto
      ),
      prikazRabata: new FormControl(
        model?.prikazRabata ??
          formConfiguration?.prikazRabata ??
          PrikazRabata.PROCENAT
      ),
      paritet: new FormControl(model?.paritet ?? Paritet.EMPTY),
      memorandumId: new FormControl(model?.memorandumId, {
        validators:
          vrsta === VrstaRacuna.IZLAZNI
            ? [Validators.required]
            : [Validators.nullValidator],
      }),
      brojNarudzbenice: new FormControl(model?.brojNarudzbenice, {
        validators: [Validators.maxLength(128)],
      }),
      brojTendera: new FormControl(model?.brojTendera, {
        validators: [Validators.maxLength(128)],
      }),
      brojUgovora: new FormControl(model?.brojUgovora, {
        validators: [Validators.maxLength(128)],
      }),
      sifraObjekta: new FormControl(model?.sifraObjekta, {
        validators: [Validators.maxLength(64)],
      }),
      relevantniDokumentListItemDtoArray: this._relevantniDokumentFS.GetFormArray(
        model?.relevantniDokumentListItemDtoArray
      ),
      koristitiPrethodnuGodinu: new FormControl(
        model?.koristitiPrethodnuGodinu ?? false
      ),
      brojPaleta: new FormControl(model?.brojPaleta ?? 0),
      brojRamova: new FormControl(model?.brojRamova ?? 0),
      netoTezina: new FormControl(model?.netoTezina ?? 0),
      brutoTezina: new FormControl(model?.brutoTezina ?? 0),
      koleto: new FormControl(model?.koleto ?? 0),
      interniBrojZaRutiranje: new FormControl(model?.interniBrojZaRutiranje, {
        validators: [Validators.maxLength(64)],
      }),
      datumNarudzbenice: new FormControl(model?.datumNarudzbenice),
      brojOtpremnice: new FormControl(model?.brojOtpremnice),
      datumOtpremnice: new FormControl(model?.datumOtpremnice),
      imeOsobeOvlasceneZaPotpisivanje: new FormControl(
        model?.imeOsobeOvlasceneZaPotpisivanje ??
          formConfiguration?.imeOsobeOvlasceneZaPotpisivanje
      ),
    });
  }

  private _getTomorrow(): Date {
    const newValue = new Date();
    newValue.setDate(newValue.getDate() + 1);
    return newValue;
  }
}
